const iframe = document.createElement('iframe')
iframe.id = 'harvest-iframe'

const dialog = document.createElement('dialog')
dialog.className = 'harvest-dialog'
dialog.appendChild(iframe)

dialog.addEventListener('click', close, { capture: true })

document.addEventListener('keyup', ({ key }) => key === 'Escape' && close(), {
  capture: true,
})

export function open(url, timer) {
  iframe.src = url
  timer.insertAdjacentElement('afterend', dialog)
  dialog.showModal()
}
export function close() {
  dialog.close()
  dialog.remove()
}
export function adjustHeight(height) {
  iframe.style.height = `${height}px`
  dialog.style.height = `${height}px`
}
